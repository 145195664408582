import React, { useState, useEffect } from "react";
import Personal from "./formularios/personal";
import Contacto from "./formularios/contacto";
import Academico from "./formularios/academico";
import Carrera from "./formularios/carrera";
import Final from "./formularios/final";
import logo from "./img/logo2023.png";
import fondo from "./img/adm2024.jpg";
import testimonio1 from "./img/testimonio1_rs.jpg";
import testimonio2 from "./img/testimonio2_ca.jpg";
import testimonio3 from "./img/testimonio3_kc.jpg";
import ModalHorarios from "./formularios/horarios";
import ModalPrematriculado from "./formularios/prematriculado";
import area_administracion from "./img/area-administracion-cft.jpg";
import area_educacion from "./img/area-educacion-cft.jpg";
import area_salud from "./img/area-salud-cft.jpg";
import area_tecnologia from "./img/area-tecnologia-cft.jpg";

import {
  base64DecodeUnicode,
  base64EncodeUnicode,
  prepararUrl,
} from "../../_helpers";

//Libreria del validador del Rut
import { validate, clean, format, getCheckDigit } from "rut.js";
//Libreria alertas
import { showToastMessageError, showToastMessageSuccess } from "../toast";

//Objeto
const initialState = {
  rut: "",
  dv: "",
  fechanac: "",
  nombres: "",
  primerapellido: "",
  segundoapellido: "",
  genero: "",
  nacionalidad: "",
  region_residencia: "",
  comuna_residencia: "",
  residencia: "",
  telefono: "",
  conftelefono: "",
  email: "",
  confemail: "",
  nombre_emergencia: "",
  telefono_emergencia: "",
  region: "",
  comuna: "",
  colegio: "",
  situacion_actual: "",
  carrera: "",
  jornada: "",
};

function Home() {
  //Le agregue 1 para que ingrese de inmediato a ese formulario
  //useState = Hook
  const [etapas, setEtapas] = useState(1);
  const [state, setState] = useState(initialState);
  const [error, setError] = useState({});
  const [comuna_residencia, setComunaResidencia] = useState([
    { codcom: "IQUIQUE", nomcomuna: "IQUIQUE" },
    { codcom: "ALTO HOSPICIO", nomcomuna: "ALTO HOSPICIO" },
  ]);
  const [nacionalidades, setNacionalidades] = useState([
    { codnacionalidad: "Chilena", nacionalidad: "Chilena" },
    { codnacionalidad: "Colombiana", nacionalidad: "Colombiana" },
  ]);
  const [regiones, setRegiones] = useState([
    { codregion: "1", descripcion: "Tarapaca" },
    { codregion: "2", descripcion: "Antofagasta" },
  ]);
  const [comuna_academico, setComunaAcademico] = useState([
    { codcom: "IQUIQUE", nomcomuna: "IQUIQUE" },
    { codcom: "ALTO HOSPICIO", nomcomuna: "ALTO HOSPICIO" },
  ]);
  const [colegios, setColegios] = useState([
    { codcol: 107, nombre: "LICEO LIBERTADOR GRAL BERNARDO O HIGGINS" },
    { codcol: 110, nombre: "ESCUELA DOMINGO SANTA MARIA" },
  ]);
  const [carreras, setCarreras] = useState([
    { codcarr: "SCAPU", nombre_c: "TNS EN ADMINISTRACIÓN PÚBLICA" },
    { codcarr: "SCAFI", nombre_c: "TNS EN ANÁLISIS FINANCIERO" },
  ]);
  const [jornadas, setJornadas] = useState([
    { jornada: "D", descripcion: "DIURNA" },
    { jornada: "V", descripcion: "VESPERTINO" },
  ]);
  const [visible, setVisible] = useState("Mostrar");
  const [guardando, setGuardando] = useState(false);
  const [situacion_actual, setSituacionActual] = useState([
    { codsituacion: 1, descripcion: "Primero Medio" },
  ]);
  const [scroll, setScroll] = useState(0);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  //Condiciones del Estudiante
  const [desactivaCondicones, setDesactivaCondiciones] = useState(1);
  const [CheckedCondiciones, setIsChecked] = useState(false);
  //Estudios Extranjeros
  const [desactivaEstudiosExtr, setDesactivaEstudiosExtr] = useState(1);
  const [checkedEstudiosExtr, setIsCheckedEstudiosExtr] = useState(false);

  window.onscroll = function () {
    let y = window.scrollY;
    //console.log(y);
    setScroll(y);
  };

  const handleChangeChbxCondiciones = (event) => {
    setIsChecked(event.target.checked);

    if (event.target.checked === false) {
      setDesactivaCondiciones(1);
    } else if (event.target.checked === true) {
      setDesactivaCondiciones(2);
    }
  };

  const _handleChangeChbxEstudiosExtr = (event) => {
    setIsCheckedEstudiosExtr(event.target.checked);

    if (event.target.checked === false) {
      setDesactivaEstudiosExtr(1);
    } else if (event.target.checked === true) {
      setDesactivaEstudiosExtr(2);
    }
  };

  //Validar input obligatorios
  const handleChangeError = (name) => {
    if (name in error) {
      delete error[name];
    }
  };

  const _handleChangeSoloNum = (e) => {
    const { name, value } = e.target;
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
    setState((prevState) => ({
      ...prevState,
      [name]: removeNonNumeric(value),
    }));
  };

  //Validar número en campo rut
  const _handleOnChangeNum = (e) => {
    const { name, value } = e.target;

    if (state.rut.length === 0 && value === "0") return false;

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    setState((prevState) => ({
      ...prevState,
      [name]: removeNonNumeric(value),
      dv: removeNonNumeric(value) ? getCheckDigit(removeNonNumeric(value)) : "",
    }));

    //Para cuando escriben valores en las cajas, elimina indicación de que falta completar campo
    handleChangeError(name);
  };

  //Validar campos de textos
  const _handleOnChangeText = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    //Para cuando escriben valores en las cajas, elimina indicación de que falta completar campo
    handleChangeError(name);
  };

  async function _handleClickGuardarInscripcion() {
    if (etapas === 4) {
      if (!validateFormCarrera()) {
        showToastMessageError("Verifique campos");
        return false;
      }
    }

    setGuardando(true);

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(state),
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "8");

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        //console.log(JSON.stringify(resp,null,3));
        showToastMessageSuccess(resp.message);
        setState(initialState);
        setEtapas(5);
        toggleVisibility("Ocultar");
        setGuardando(false);
      } else {
        if (response.status === 400) {
          showToastMessageError(resp.message);
          setError(resp.errores);
          setGuardando(false);
        }
      }
    } catch (error) {
      setGuardando(false);
    }
  }

  //Función para enviar información a backend (Nacionalidades)
  async function getNacionalidades() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "1", []);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_nacionalidades = resp.data;
        setNacionalidades(v_nacionalidades);
      } else {
      }
    } catch (error) {}
  }

  //Función para enviar información a backend (Comunas)
  async function getRegiones() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "3", []);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_regiones = resp.data;
        setRegiones(v_regiones);
      } else {
      }
    } catch (error) {}
  }

  async function getSituacionActual() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "14", []);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_situacion_actual = resp.data;
        setSituacionActual(v_situacion_actual);
      } else {
      }
    } catch (error) {}
  }

  async function getComunaResidencia() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "2", [
        { name: "codregion", value: state.region_residencia },
      ]);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_comuna_residencia = resp.data;
        setComunaResidencia(v_comuna_residencia);
      } else {
      }
    } catch (error) {}
  }

  async function getComunaAcademico() {
    //console.log(state.region);
    //return false;

    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "4", [
        { name: "codregion", value: state.region },
      ]);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_comuna_academico = resp.data;
        setComunaAcademico(v_comuna_academico);
        //console.log(JSON.stringify(resp.data,null,3));
      } else {
      }
    } catch (error) {}
  }

  async function getColegios() {
    //console.log(state.comuna);
    //return false;

    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "5", [
        { name: "codcom", value: state.comuna },
      ]);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_colegios = resp.data;
        setColegios(v_colegios);
      } else {
      }
    } catch (error) {}
  }

  async function getCarreras() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "6", []);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_carreras = resp.data;
        setCarreras(v_carreras);
      } else {
      }
    } catch (error) {}
  }

  async function getJornadas() {
    try {
      const requestOptions = {
        method: "GET",
      };

      let v_url = prepararUrl("prematricula", "backend", "generales", "7", []);

      const response = await window.fetch(v_url, requestOptions);
      const resp = await response.json();

      if (response.ok) {
        let v_jornadas = resp.data;
        setJornadas(v_jornadas);
      } else {
      }
    } catch (error) {}
  }

  //Validaciones de formularios
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validateFormPersonal = () => {
    let field = state;
    let error = {};
    let formValid = true;

    if (!field.rut) {
      formValid = false;
      error["rut"] = "Ingrese RUT";
    }
    if (!field.fechanac) {
      formValid = false;
      error["fechanac"] = "Ingrese Fecha Nacimiento";
    }
    if (!field.nombres) {
      formValid = false;
      error["nombres"] = "Ingrese Nombres";
    }
    if (!field.primerapellido) {
      formValid = false;
      error["primerapellido"] = "Ingrese Primer Apellido";
    }
    if (!field.genero) {
      formValid = false;
      error["genero"] = "Seleccione Genero";
    }
    if (!field.nacionalidad) {
      formValid = false;
      error["nacionalidad"] = "Seleccione Nacionalidad";
    }
    if (!field.region_residencia) {
      formValid = false;
      error["region_residencia"] = "Seleccione Región";
    }

    if (!field.comuna_residencia) {
      formValid = false;
      error["comuna_residencia"] = "Seleccione Comuna";
    }

    if (field.nacionalidad > 1) {
      if (!field.residencia) {
        formValid = false;
        error["residencia"] = "Seleccione Tipo de Residencia";
      }
    }

    setError(error);
    return formValid;
  };

  const validateFormContacto = () => {
    let field = state;
    let error = {};
    let formValid = true;

    if (!field.telefono) {
      formValid = false;
      error["telefono"] = "Ingrese Teléfono Celular";
    }
    if (!field.conftelefono) {
      formValid = false;
      error["conftelefono"] = "Confirme Teléfono Celular";
    }

    if (!field.email) {
      formValid = false;
      error["email"] = "Ingrese Email";
    } else {
      if (!isValidEmail(field.email)) {
        formValid = false;
        error["email"] = "Email inválido";
      }
    }

    if (!field.confemail) {
      formValid = false;
      error["confemail"] = "Confirme Email";
    } else {
      if (!isValidEmail(field.confemail)) {
        formValid = false;
        error["confemail"] = "Email inválido";
      }
    }

    if (field.telefono != field.conftelefono) {
      formValid = false;
      error["telefono"] = "Teléfonos distintos";
      error["conftelefono"] = "Teléfonos distintos";
    }

    if (field.email != field.confemail) {
      formValid = false;
      error["email"] = "Email distintos";
      error["confemail"] = "Email distintos";
    }

    if (!field.nombre_emergencia) {
      formValid = false;
      error["nombre_emergencia"] = "Ingrese Nombre Emergencia";
    }

    if (!field.telefono_emergencia) {
      formValid = false;
      error["telefono_emergencia"] = "Ingrese Teléfono Emergencia";
    }

    if (field.telefono_emergencia == field.telefono) {
      formValid = false;
      error["telefono_emergencia"] =
        "Teléfono Emergencia debe ser distinto al de Contacto";
    }

    setError(error);
    return formValid;
  };

  const validateFormAcademico = () => {
    let field = state;
    let error = {};
    let formValid = true;

    if (!field.region && desactivaEstudiosExtr === 1) {
      formValid = false;
      error["region"] = "Seleccione Región";
    }
    if (!field.comuna && desactivaEstudiosExtr === 1) {
      formValid = false;
      error["comuna"] = "Seleccione Comuna";
    }
    if (!field.colegio && desactivaEstudiosExtr === 1) {
      formValid = false;
      error["colegio"] = "Seleccione Colegio";
    }
    if (!field.situacion_actual) {
      formValid = false;
      error["situacion_actual"] = "Seleccione Año";
    }
    setError(error);
    return formValid;
  };

  const validateFormCarrera = () => {
    let field = state;
    let error = {};
    let formValid = true;

    if (!field.carrera) {
      formValid = false;
      error["carrera"] = "Seleccione Carrera";
    }

    if (!field.jornada) {
      formValid = false;
      error["jornada"] = "Seleccione Jornada";
    }

    setError(error);
    return formValid;
  };

  //Funciones para etapas
  function SiguienteEtapa() {
    if (etapas === 1) {
      if (!validateFormPersonal()) {
        showToastMessageError("Verifique campos");
        return false;
      }
    }
    if (etapas === 2) {
      if (!validateFormContacto()) {
        showToastMessageError("Verifique campos");
        return false;
      }
    }
    if (etapas === 3) {
      if (!validateFormAcademico()) {
        showToastMessageError("Verifique campos");
        return false;
      }
    }
    setEtapas(etapas + 1);
  }

  function DevolverEtapa() {
    setEtapas(etapas - 1);
  }

  function Porcentaje() {
    if (etapas === 1) {
      return "0%";
    } else if (etapas === 2) {
      return "30%";
    } else if (etapas === 3) {
      return "60%";
    } else if (etapas === 4) {
      return "100%";
    }
  }

  function HabilitaCondiciones() {
    if (desactivaCondicones === 1) {
      return "disabled";
    } else {
      return "";
    }
  }

  //Botones para formularios
  const BtnAtras = () => {
    return (
      <>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-gris"
            onClick={DevolverEtapa}
          >
            Atrás
          </button>
        </div>
      </>
    );
  };

  const BtnSiguiente = () => {
    return (
      <>
        <button type="button" className="btn btn-azul" onClick={SiguienteEtapa}>
          Siguiente
        </button>
      </>
    );
  };

  const BtnEnviar = () => {
    return (
      <button
        type="button"
        className="btn btn-azul"
        disabled={guardando || HabilitaCondiciones()}
        onClick={() => _handleClickGuardarInscripcion()}
      >
        {guardando ? "Enviando..." : "Enviar"}
      </button>
    );
  };

  const ChkCondiciones = () => {
    return (
      <>
        <input
          className="form-check-input"
          type="checkbox"
          id="condiciones"
          name="condiciones"
          onChange={handleChangeChbxCondiciones}
          checked={CheckedCondiciones}
        />
        &nbsp;&nbsp;Acepto que conforme a lo estipulado en la ley N°19.628
        autorizo al almacenamiento de datos personales y su utilización en lo
        que la entidad estime pertinente, en resguardo siempre de la vida
        privada de los involucrados.
      </>
    );
  };

  const WarningExtranjero = () => {
    return (
      <div className="alert alert-warning" role="alert">
        <b>Importante:</b> Los extranjeros y chilenos que hubiesen terminado su
        enseñanza media en otro país deben cumplir con los procedimientos
        establecidos para el reconocimiento de sus estudios en Chile. Todo
        postulante extranjero debe tener permanencia definitiva en Chile, salvo
        las excepciones que establezca el Reglamento de Admisión.
      </div>
    );
  };

  //Trigger que se ejecuta cuando se renderiza el componente en navegador
  useEffect(() => {
    getNacionalidades();
    getRegiones();
    getColegios();
    getCarreras();
    getJornadas();
    getSituacionActual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Personal
  useEffect(() => {
    if (state.region_residencia) {
      getComunaResidencia();
    }
  }, [state.region_residencia]);

  //Académico
  useEffect(() => {
    if (state.region) {
      getComunaAcademico();
    }
  }, [state.region]);

  useEffect(() => {
    if (state.comuna) {
      getColegios();
    }
  }, [state.comuna]);

  var sectionStyle = {
    backgroundSize: "cover",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fondo})`,
  };

  return (
    <>
      {visible && (
        <>
          <div className="min-vh-100 min-vw-100" style={sectionStyle}>
            <div className="row">
              <div className="col-12 col-sm-6 mt-2">
                <div className="card bg-transparent text-white mt-4">
                  <div
                    className="card-body text-center"
                    style={{ marginTop: "20%" }}
                  >
                    <h1 className="card-title">
                      <b>Pre Matrícula 100% Online</b>
                    </h1>
                    <h4 className="card-text">
                      ¡Ya puedes reservar tu cupo para estudiar con nosotros!
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-5">
                <div className="card mt-4">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <img src={logo} width="220px" height="170px" />
                      <h4 className="card-title">
                        Proceso de Admisión 2025
                        <br /> Formulario de Pre Matrícula
                      </h4>
                    </div>
                    <div className="card-text">
                      <div className="progress custom-progress-bar mb-3">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: Porcentaje() }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      {etapas === 1 && (
                        <Personal
                          regiones={regiones}
                          comuna_residencia={comuna_residencia}
                          nacionalidades={nacionalidades}
                          handleOnChangeNum={_handleOnChangeNum}
                          state={state}
                          handleOnChangeText={_handleOnChangeText}
                          error={error}
                        />
                      )}
                      {etapas === 2 && (
                        <Contacto
                          state={state}
                          handleOnChangeText={_handleOnChangeText}
                          error={error}
                          handleChangeSoloNum={_handleChangeSoloNum}
                        />
                      )}
                      {etapas === 3 && (
                        <Academico
                          regiones={regiones}
                          comuna_academico={comuna_academico}
                          colegios={colegios}
                          situacion_actual={situacion_actual}
                          state={state}
                          handleOnChangeText={_handleOnChangeText}
                          handleChangeChbxEstudiosExtr={
                            _handleChangeChbxEstudiosExtr
                          }
                          CheckedEstudiosExtranjero={checkedEstudiosExtr}
                          error={error}
                        />
                      )}
                      {etapas === 4 && (
                        <Carrera
                          carreras={carreras}
                          jornadas={jornadas}
                          state={state}
                          handleOnChangeText={_handleOnChangeText}
                          error={error}
                        />
                      )}

                      <div className="col-md-12">
                        <div className="mb-3">
                          {etapas === 4 && <ChkCondiciones />}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          {etapas === 4 &&
                            ((state.nacionalidad === "1" &&
                              checkedEstudiosExtr === true) ||
                              state.nacionalidad != "1") && (
                              <WarningExtranjero />
                            )}
                        </div>
                      </div>
                      <div className="row">
                        {etapas > 1 && <BtnAtras />}
                        <div className="col-auto">
                          {etapas === 4 ? <BtnEnviar /> : <BtnSiguiente />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ backgroundColor: "#53aaca" }}>
            <div className="col-12">
              <div className="text-center pt-4 pb-3">
                <h4 className="card-title text-white">
                  ¿Por qué estudiar en el CFT Estatal Tarapacá?
                </h4>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8 pb-3">
                  <div className="card testimonial-card p-4 ps-3 pe-3 m-2 mt-3">
                    <div className="quotations">
                      <div className="row">
                        <div className="col-2">
                          <div className="d-flex align-items-center">
                            <img
                              src={testimonio1}
                              alt="Foto testimonio"
                              className="rounded-circle card-img"
                              style={{ width: "85%" }}
                            />
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="card-body">
                            <p className="card-text">
                              "He tenido una gran experiencia en el CFT, siento
                              que he aprendido mucho, cosas que jamás pensé que
                              iba a llegar a saber, hoy las sé. Yo soy mamá,
                              trabajo y estudio en horario vespertino. El hecho
                              de poder estudiar en Alto Hospicio, hace mucho más
                              fácil venir a clases y poder llegar a la casa a
                              estar con mi familia."
                            </p>
                            <p className="card-title">Rosa Santander</p>
                            <p className="card-text">
                              Quinto Semestre de TNS Mecánico en Mantenimiento
                              Predictivo
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-8 pb-3">
                  <div className="card testimonial-card p-4 ps-3 pe-3 m-2 mt-3">
                    <div className="quotations">
                      <div className="row">
                        <div className="col-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "100%" }}
                          >
                            <img
                              src={testimonio2}
                              alt="Foto testimonio"
                              className="rounded-circle card-img"
                              style={{ width: "85%" }}
                            />
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="card-body">
                            <p className="card-text">
                              "El CFT me ha entregado muchas herramientas que me
                              han servido para profesionalizarme y avanzar en el
                              rubro que escogí. Yo ingresé a la empresa Salfa
                              durante mi primer año de salida intermedia como
                              Operario Logístico y ha pasado un año y ya me
                              encuentro trabajando como Coordinador Logístico.
                              Gracias al CFT, estoy donde estoy ahora."
                            </p>
                            <p className="card-title">Camilo Antilao</p>
                            <p className="card-text">
                              Quinto Semestre de TNS en Logística
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-8 mb-4">
                  <div className="card testimonial-card p-4 ps-3 pe-3 m-2 mt-3">
                    <div className="quotations">
                      <div className="row">
                        <div className="col-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "100%" }}
                          >
                            <img
                              src={testimonio3}
                              alt="Foto testimonio"
                              className="rounded-circle card-img"
                              style={{ width: "85%" }}
                            />
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="card-body">
                            <p className="card-text">
                              "Mi experiencia en el CFT Estatal Tarapacá ha sido
                              súper buena. Yo era dueña de casa. En un principio
                              pensé que me iba a hacer muy difícil adaptarme, ya
                              que estuve harto tiempo sin estudiar, pero el CFT
                              me entregó todas las herramientas para ingresar
                              más preparada. Los profesores ayudan mucho, nos
                              guían y hacen retroalimentaciones constantemente."
                            </p>
                            <p className="card-title">Katherine Carvajal</p>
                            <p className="card-text">
                              Quinto Semestre de TNS en Administración de
                              Empresas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {etapas === 5 && <Final state={state} />}
      {etapas === 5 && <ModalPrematriculado />}

      <div className="container" style={{ padding: "1.5em" }}>
        <div className="areas-titulo text-center">
          <h4>Carreras Técnicas de Nivel Superior</h4>
        </div>
        <div className="row">
          <div className="col-12 col-sm-3 text-center">
            <img
              className="img-fluid"
              src={area_administracion}
              width="230px"
              height="226px"
            />
            <h5 style={{ fontWeight: "600", color: "#004458" }}>
              Área Administración
            </h5>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-administracion-empresas/"
              >
                TNS en Administración de Empresas
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-administracion-publica/"
              >
                TNS en Administración Pública
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-comercio-exterior/"
              >
                TNS en Comercio Exterior
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-3 text-center">
            <img
              className="img-fluid"
              src={area_educacion}
              width="230px"
              height="226px"
            />
            <h5 style={{ fontWeight: "600", color: "#004458" }}>
              Área Educación
            </h5>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-asistente-educacion/"
              >
                TNS Asistente de la Educación
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-educacion-parvularia/"
              >
                TNS en Educación Parvularia
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-3 text-center">
            <img
              className="img-fluid"
              src={area_salud}
              width="230px"
              height="226px"
            />
            <h5 style={{ fontWeight: "600", color: "#004458" }}>Área Salud</h5>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-enfermeria/"
              >
                TNS en Enfermería
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-trabajo-social/"
              >
                TNS en Trabajo Social
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-3 text-center">
            <img
              className="img-fluid"
              src={area_tecnologia}
              width="230px"
              height="226px"
            />
            <h5 style={{ fontWeight: "600", color: "#004458" }}>
              Área Tecnología
            </h5>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-electricidad-industrial/"
              >
                TNS en Electricidad Industrial
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-logistica/"
              >
                TNS en Logística
              </a>
            </div>
            <div className="areas-items">
              <a
                className="areas-items-link"
                target="_blank"
                href="https://www.cftestataltarapaca.cl/admision/tns-mecanico/"
              >
                TNS Mecánico en Mantenimiento Predictivo
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={scroll > 80 ? "desva2" : "desva"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-lg-10 col-xl-6 mx-auto"
          style={{
            position: "fixed",
            backgroundColor: "#53aaca",
            bottom: "0",
            borderRadius: "7px",
            marginBottom: "50px",
          }}
        >
          <ul className="nav justify-content-center text-center">
            <li className="nav-item">
              <a
                target="_blank"
                href="https://www.facebook.com/CFTestataltarapaca"
                className="nav-link px-2 text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.8em"
                  viewBox="0 0 600 512"
                  style={{ fill: "#ffffff" }}
                >
                  <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                </svg>
                <label className="mt-1 text-white ocultarCel">Facebook</label>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                href="https://www.instagram.com/cftestataltarapaca/"
                className="nav-link px-2 text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.8em"
                  viewBox="0 0 600 512"
                  style={{ fill: "#ffffff" }}
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
                <label className="mt-1 text-white ocultarCel">Instagram</label>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                href="https://wa.me/56978953861/?text=Hola! necesito ayuda!"
                className="nav-link px-2 text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.8em"
                  viewBox="0 0 600 512"
                  style={{ fill: "#ffffff" }}
                >
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                </svg>
                <label className="mt-1 text-white ocultarCel">Whatsapp</label>
              </a>
            </li>
            <li className="nav-item">
              <a
                target="_blank"
                href="https://twitter.com/cftestarapaca"
                className="nav-link px-2 text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.8em"
                  viewBox="0 0 600 512"
                  style={{ fill: "#ffffff" }}
                >
                  <path d="M250,3C113.6,3,3,113.6,3,250s110.6,247,247,247s247-110.6,247-247S386.4,3,250,3z M387.5,196.6c0.2,2.8,0.2,5.7,0.2,8.5 c0,87-66.2,187.2-187.2,187.2c-37.3,0-71.9-10.8-101-29.5c5.3,0.6,10.4,0.8,15.9,0.8c30.8,0,59.1-10.4,81.7-28.1 c-28.9-0.6-53.2-19.6-61.5-45.6c4.1,0.6,8.2,1,12.4,1c5.9,0,11.8-0.8,17.3-2.2c-30.1-6.1-52.8-32.6-52.8-64.6v-0.8 c8.8,4.9,18.9,7.9,29.7,8.4c-17.7-11.8-29.3-32-29.3-54.8c0-12.2,3.3-23.4,9-33.2c32.4,39.9,81.1,66,135.7,68.9 c-1-4.9-1.6-10-1.6-15.1c0-36.2,29.3-65.8,65.8-65.8c18.9,0,36.1,8,48.1,20.8c14.9-2.8,29.1-8.3,41.8-15.9 c-4.9,15.3-15.3,28.1-28.9,36.3c13.2-1.4,26.1-5.1,37.9-10.2C411.5,175.6,400.3,187.2,387.5,196.6z" />
                </svg>
                <label className="mt-1 text-white ocultarCel">Twitter</label>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="footer col-lg-12 col-xl-12 mx-auto"
        style={{ height: "60px" }}
      >
        <p className="text-center text-muted">
          <br />
          <label>
            Horarios de Atención de Lunes a Viernes 08:30 a 17:30 hrs.
          </label>
        </p>
      </div>
      <div
        className="footer col-lg-12 col-xl-12 mx-auto"
        style={{ height: "150px", backgroundColor: "#53aaca" }}
      >
        <p className="text-center text-muted">
          <br />
          <label>
            Admisión CFT Estatal Tarapacá &middot; Juanita Fernández 3199, Alto
            Hospicio
          </label>
        </p>
      </div>
    </>
  );
}

export default Home;
